import React, { useState, ChangeEvent, FormEvent } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import bunchup from "../../assets/bunchuplogo copy.png";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { AuthLogin } from "../../services/AuthService";
import { Link } from "react-router-dom";
import playstore from "../../assets/playstore.webp";
import appstore from "../../assets/appstore.webp";

interface LoginProps {
  // onLogin: () => void;
};

const Login: React.FC<LoginProps> = () => {

  const [formData, setFormData] = useState({
    telephone: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "telephone") {
      setFormData({
        ...formData,
        [name]: value.startsWith("+91") ? value : `+91${value}`,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const MobileNumbers = ["+919689675891","+919404131807","+919689675896", "+917900186313"]

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();

    if (!MobileNumbers.includes(formData.telephone)) {
      toast.error("Sorry you are not Admin!");
      return;
    };

    setLoading(true);
    try {
      const response = await AuthLogin(formData);
      if (response) {
        navigate("/");
      }
    } catch (err) {
      console.log(err);
      toast.error("Invalid Credentials");
    } finally {
      setLoading(false);
    }
  };

  const handleAppStore = () => {
    toast.success("We will be launching soon on App Store");
  };

  return (
    <div className="bg-image flex flex-col justify-center items-center ">

      <h1 className="text-[3.6vh] text-[#ffffff94] font-bold my-[2vh]">Are you Admin??</h1>
      <div className="glassmorphism-container z-30 relative w-[95%] mx-auto md:w-[37vw] lg:w-[30vw] bg-opacity-50 py-[4.5vh] px-[5vh] rounded-xl shadow-3xl">
        <div className="w-[20vh] h-[20vh] blurclass bg-[#4d3306] rounded-full absolute -z-20 -top-[10%] -left-[10%]"></div>

        <img src={bunchup} className="w-[10vh] mx-auto mb-[4vh]" />

        <form className="flex flex-col" onSubmit={handleLogin}>
          <div className="">
            <label className="text-[2.6vh] font-semibold text-[#d5d5d5]">
              Mobile Number
            </label>
            <input
              type="tel"
              name="telephone"
              value={formData.telephone}
              maxLength={13}
              placeholder="Enter your number"
              className="border focus:border-[#fff333] border-[#545454] focus:border-1 bg-transparent p-2 rounded-md w-full text-[2.5vh] text-white my-[1vh] focus:outline-none"
              onChange={handleChange}
              required
            />
          </div>

          <div className="relative mt-[2.5vh]">
            <label className="text-[2.6vh] font-semibold text-[#d5d5d5]">
              Password:
            </label>
            <input
              type={passwordVisible ? "text" : "password"}
              name="password"
              placeholder="Enter your Password"
              value={formData.password}
              className="border focus:border-[#fff333] border-[#545454] focus:border-1 bg-transparent p-2 rounded-md w-full text-[2.5vh] text-white my-[0.5vh] focus:outline-none"
              onChange={handleChange}
              required
            />
            <button
              type="button"
              className="absolute inset-y-0 top-[35%] right-0 flex items-center pr-3"
              onClick={togglePasswordVisibility}
            >
              {passwordVisible ? (
                <AiFillEyeInvisible size={23} color="#d5d5d5" />
              ) : (
                <AiFillEye size={23} color="#d5d5d5" />
              )}
            </button>
          </div>

          <button
            className="submitbtn text-[1.7vh] md:text-[2.6vh] my-[3vh] rounded-md font-semibold hover:scale-105 transition-all text-[#111] w-[100%] mx-auto py-[1.5vh] md:py-[1.3vh]"
            type="submit"
          >
            {loading ? (
              <div className="flex flex-row justify-center items-center">
                <div className="spinner"></div>
              </div>
            ) : (
              <>Login</>
            )}
          </button>
        </form>
        <div className="flex flex-row justify-center items-center">
          <Link
            to="https://play.google.com/store/apps/details?id=com.gaurav1920.bunchupapp"
            target="_blank"
          >
            <img
              src={playstore}
              className="w-[12vh] md:w-[17vh] mx-[1vh] hover:scale-110 transition-all duration-300"
            />
          </Link>
          <img
            onClick={handleAppStore}
            src={appstore}
            className="w-[12vh] md:w-[17vh] cursor-pointer mx-[1vh] hover:scale-110 transition-all duration-300"
          />
        </div>
        <p className="intro text-[#8d8d8d] left-0 absolute -bottom-[9vh] text-center font-medium text-[1.9vh] my-[1vh]">
          Join now to connect with like-minded individuals, share and develop
          your plans and earn Buncoins😊
        </p>
      </div>
      {/* <p className="text-[#cccccc] font-medium my-[0.5vh]">Made by Gaurav Madan</p> */}
    </div>
  );
};

export default Login;