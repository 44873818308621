// src/services/userService.ts
import axios from "axios";
import Cookies from "js-cookie";
import { apiUrl } from "../utils/apiConfig";
import { Sidekick } from "../types/sidekicks";

export const fetchAllUsersDetails = async (page: number, limit: number) => {
  try {
    const accessToken = Cookies.get("access_token");
    const response = await axios.get(`${apiUrl}/user`, {
      params: {
        page,
        limit,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.data.success) {
      return {
        users: response.data.data.results,
        totalUsers: response.data.data.totalResults,
        totalPages: response.data.data.totalPages,
      };
    }
    throw new Error("Failed to fetch total users");
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const getPublicProfileDetails = async (username: string) => {
  try {
    const accessToken = Cookies.get("access_token");
    const response = await axios.post(
      `${apiUrl}/user/public-profile`,
      { username },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Failed to fetch public profile details");
    }
  } catch (error) {
    console.error("Error fetching public profile details:", error);
    throw new Error("Failed to fetch public profile details");
  }
};

export const getUserSidekickList = async (friendId: string): Promise<Sidekick[]> => {
  try {
    const accessToken = Cookies.get("access_token");
    const response = await axios.get(`${apiUrl}/user/friend/list/${friendId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const friends = response.data.data.friends;
    return friends; 
  } catch (error) {
    console.error("Error fetching mutual friends:", error);
    throw new Error("Failed to fetch mutual friends");
  }
};